import CardHighlightsHorizontal from "../CardHighlightsHorizontal";
import CardHighlightsVertical from "../CardHighlightsVertical";
import BikeVariantCard from "./BikeVariantCard";
import React from "react";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import { queryBikes } from "../../../graphql/queries";
import useSWR from "swr";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  productHighlights: {
    marginBottom: 32,
  }
}));

const BikeHighlights = (props) => {
  const {
    anchor,
    recommendation,
    highlightsClass,
    horizontalScrollClassName,
    title,
    verticalScroll,
    align,
    itemClass,
    getTitle,
    getSubTitle,
    forceLoad,
    typographyVariant
  } = props;
  const classes = useStyles();
  const getFilter = () => {
    if (recommendation.bike.bikeSeries) {
      return {
        action: "getByBikeSeries",
        bikeSeries: recommendation.bike.bikeSeries,
        excludeIds: recommendation.bike.id,
        visibility: "public",
        size: 8,
        sort: "default",
      };
    }
    else if (recommendation.bike.bikeFamily) {
      return {
        action: "getByBikeFamily",
        bikeFamily: recommendation.bike.bikeFamily,
        excludeIds: recommendation.bike.id,
        visibility: "public",
        size: 8,
        sort: "default",
      };
    }
    return null;
  };

  const filter = getFilter();
  const { data } = useSWR([!!filter && queryBikes, JSON.stringify(filter)]);
  if(!filter){
    return null;
  }

  if (!data) {
    return null;
  }
  var bikes = data?.queryBikes?.items;

  
  if (!bikes || bikes.length <= 0) {
    return null;
  }

  return (
    <div className={classes.productHighlights} id={anchor}>
      {
        verticalScroll ? (
          <CardHighlightsVertical
            typographyVariant={typographyVariant}
            className={highlightsClass ? highlightsClass : ""}
            itemClass={itemClass}
            title={getTitle ? getTitle(bikes) : title}
            subtitle={getSubTitle ? getSubTitle(bikes) : undefined}
            align={align}
            cards={bikes.map((bike) =>
                <BikeVariantCard key={bike.id} forceLoad={forceLoad ? forceLoad : 1} bike={bike} />
            )}
          />
        ) : (
          <CardHighlightsHorizontal
            className={highlightsClass ? highlightsClass : ""}
            horizontalScrollClassName={!verticalScroll ? horizontalScrollClassName : ""}
            title={title}
            cards={bikes.map((bike) => (
              <BikeVariantCard key={bike.id} forceLoad={forceLoad ? forceLoad : 1} bike={bike} />
            ))}
          />
        )
      }
    </div>
  );
};

export default BikeHighlights;
