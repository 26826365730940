
// Example:
// [{"a":"x", "b": 1}, {"a":"x", "b": 2}, {"a":"y", "b": 9}]
// =>
// {
//    "x": [{"a":"x", "b": 1}, {"a":"x", "b": 2}]
//    "y": [{"a":"y", "b": 9}]
// }
//
export const groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  

export const groupByFn = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
arr.reduce((groups, item) => {
  (groups[key(item)] ||= []).push(item);
  return groups;
}, {} as Record<K, T[]>);


  